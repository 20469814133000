import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Navbar({ SingleProject }) {
  const location = useLocation();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    if (navRef.current) {
      if (isNavExpanded) {
        navRef.current.style.height = `${navRef.current.scrollHeight}px`;
      } else {
        navRef.current.style.height = '0px';
      }
    }
  }, [isNavExpanded]);

  const toggleNav = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  return (
    <section id="contact_us" className="nav no-padding" data-scroll-index="5">
      <nav
        className={
          SingleProject
            ? "navbar navbar-expand-lg blog-nav"
            : "navbar navbar-expand-lg"
        }>
        <div className="container">
          <a
            className="logo"
            href="/"
            data-scroll-nav={SingleProject ? null : "0"}>
            <img
              src="https://ik.imagekit.io/nbnhyhh5w/logo/logo.png?updatedAt=1696147765686"
              alt="IDEAL CHIP LOGO"
              title="IDEAL CHIP LOGO"
            />
            <img
              src="https://ik.imagekit.io/nbnhyhh5w/logo/name.png?updatedAt=1696147765510"
              alt="IDEAL CHIP NAME"
              title="IDEAL CHIP NAME"
            />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNav}
            aria-controls="navbarContent"
            aria-expanded={isNavExpanded}
            aria-label="Toggle navigation">
            <span className="icon-bar">
              <i className="fas fa-bars"></i>
            </span>
          </button>

          <div ref={navRef} className={`navbar-collapse ${isNavExpanded ? "expanded" : ""}`} id="navbarContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  href="/">
                  home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/about-us" ? "active" : ""
                  }`}
                  href="/about-us">
                  about us
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/product" ? "active" : ""
                  }`}
                  href="/product">
                  products
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    location.pathname === "/contact_us" ? "active" : ""
                  }`}
                  href="/contact_us">
                    contact us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
}
