import React, { useState, useEffect } from 'react';

export default function Counter() {
const [isVisible, setIsVisible] = useState(false);
const [counters, setCounters] = useState({
yearsOfExp: 0,
projectsCompleted: 0,
clients: 0,
happyEmployees: 0
});

const handleScroll = () => {
const counterSection = document.querySelector('.numbers');
const position = counterSection.getBoundingClientRect();

if (position.top >= 0 && position.bottom <= window.innerHeight) { setIsVisible(true); } }; useEffect(()=> {
  window.addEventListener('scroll', handleScroll);

  return () => {
  window.removeEventListener('scroll', handleScroll);
  };
  }, []);

  useEffect(() => {
  let interval = null;

  if (isVisible) {
  interval = setInterval(() => {
  setCounters(prev => ({
  yearsOfExp: prev.yearsOfExp < 26 ? prev.yearsOfExp + 1 : 26, projectsCompleted: prev.projectsCompleted < 980 ?
    prev.projectsCompleted + 10 : 980, clients: prev.clients < 134 ? prev.clients + 1 : 134, happyEmployees:
    prev.happyEmployees < 45 ? prev.happyEmployees + 1 : 45, })); }, 50); } if ((counters.yearsOfExp===26 &&
    counters.projectsCompleted===980 && counters.clients===134 && counters.happyEmployees===45)) {
    clearInterval(interval); } return ()=> clearInterval(interval);
    }, [isVisible, counters]);

    return (
    // <!--  Start Counter Section -->
    <div className="numbers bg-light-gray padding-60px-tb">
      <div className="container">
        <div className="row">
          {/* <!-- start counter item --> */}
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6 sm-margin-30px-bottom">
            <div className="item text-center bounceIn animated">
              <span className="icon"><i className="fa-solid fa-calendar-days"></i></span>
              <h6 className="count">{counters.yearsOfExp}</h6>
              <p>Years of Exp</p>
              <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-top"></div>
            </div>
          </div>
          {/* <!-- end counter item --> */}

          {/* <!-- start counter item --> */}
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6 sm-margin-30px-bottom">
            <div className="item text-center bounceIn animated">
              <span className="icon"><i className="fa-solid fa-clipboard-check"></i></span>
              <h6 className="count">{counters.projectsCompleted}</h6>
              <p>Projects Completed</p>
              <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-top"></div>
            </div>
          </div>
          {/* <!-- end counter item --> */}

          {/* <!-- start counter item --> */}
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
            <div className="item text-center xs-margin-30px-bottom bounceIn animated">
              <span className="icon"><i className="fa-solid fa-earth-europe"></i></span>
              <h6 className="count">{counters.clients}</h6>
              <p>Clients</p>
              <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-top"></div>
            </div>
          </div>
          {/* <!-- end counter item --> */}

          {/* <!-- start counter item --> */}
          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6">
            <div className="item text-center bounceIn animated">
              <span className="icon"><i className="fa-solid fa-user"></i></span>
              <h6 className="count">{counters.happyEmployees}</h6>
              <p>Happy Employees</p>
              <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-top"></div>
            </div>
          </div>
          {/* <!-- end counter item --> */}
        </div>
      </div>
    </div>
    // <!-- End Counter Section -->
    )
    }
