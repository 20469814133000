import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

export default function Category() {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    axios
      .get(`https://www.idealchip.com/backEndAPI/api/categories`)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data", error);
      });
  }, []);

  return (
    <section className="no-padding">
      <Navbar SingleProject={true} />
      <section id="sec_bg">
        <section className="blog-header">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 prodcamp">
                <h5>{"Products"}</h5>
                <a href="/">Home</a>
                {category && (
                  <a href={`/product`}>products</a>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="blog">
        <div className="container">
          <div className="row rowproductcategory">
            {category.length > 0 ? (
              category.map((post) => (
                <div
                  key={post.id}
                  className="col-lg-4 sm-margin-30px-bottom wow fadeInUp"
                  data-wow-delay="0.2 s"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInUp",
                  }}
                >
                  <a href={`/category/${post.id}`}>
                    <div
                      className='item text-center'
                      style={{ height: '240px' }}
                    >
                      <div className="content" style={{ height: "240px" }}>
                        <div
                          className="tag alt-font text-center d-flex m-auto"
                          style={{
                            height: "10rem",
                            color: "white",
                            opacity: 0.88,
                            width: "100%",
                            backgroundImage: `url(${post.image})`,
                          }}
                        >
                          <h6
                            className="m-auto p-2 font-weight-bold text-capitalize"
                            style={{ color: "whitesmoke" }}
                            href="#"
                          >
                            {post.cat_name}
                          </h6>
                        </div>
                        <div className="separator-line-horrizontal-full bg-medium-gray margin-20px-tb"></div>
                      </div>
                    </div>
                  </a>
                  
                </div>
              ))
            ) : (
              <div>
                <h6 className="container">{"loading. . ."}</h6>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
}