import React from 'react';
import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';



const Map = () => {
  const mapStyle = {
    height: '100%',
    width: '100%',
    zIndex: "-12"
  };
  const markerIcon = L.icon({
    iconUrl: 'maps-and-flags.png',
    iconSize: [50, 55],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
  const markerPosition = [32.00133643518465, 36.00105854232796];
  return (
    <MapContainer center={markerPosition} zoom={15} style={mapStyle}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="OpenStreetMap" />
      <Marker position={markerPosition} icon={markerIcon}>
        <Popup>
          <a href='https://maps.app.goo.gl/7BtJpeKDB8wFMJ537' rel="noreferrer" target='_blank' >open in google maps</a>
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
