import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

export default function About() {
  return (
    // <!-- Start About Section-->
    <div className="App">
      <Navbar />
      <section
        id="AboutTheCompany"
        className="hero no-padding-bottom"
        data-scroll-index="4">
        <div className="container margin-50px-bottom">
          <div className="row">
            <div className="col-12 center-col margin-0px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center wow fadeInUp">
              <div className="alt-font text-pink-color margin-5px-bottom text-uppercase letter-spacing-1">
                About The Company
              </div>
              <h5 className="margin-10px-bottom font-weight-300">
                We Are{" "}
                <span style={{ color: "#272567" }} className="font-weight-500">
                  iDEAL
                </span>
                <span style={{ fontWeight: "350" }}>CHiP</span>
              </h5>
              <span style={{ fontSize: "1rem", letterSpacing: "0.2px" }}>
                {" "}
                <b>Since 1997</b>{" "}
              </span>
              <div className="separator-line-horrizontal-medium-light2 bg-pink center-col"></div>
            </div>
          </div>

          <div className="row"></div>
        </div>
      </section>

      <div className="container" >
        <img
          src="https://ik.imagekit.io/nbnhyhh5w/1%20(4).jpg?updatedAt=1702121146592"
          alt=""
        />
      </div>

      <div className="container aboutusworddiv">
        <p className="aboutusword" dir="rtl" >
          {" "}
          منذ تأسيسها عام 1997، تعتبر "الشريحة المثالية" رائدة في مجال
          التكنولوجيا والابتكار. نتميز بتقديم مجموعة واسعة من الحلول التكنولوجية
          المتطورة والتي تشمل التصميم والتصنيع الإلكتروني، إنترنت الأشياء
          المتقدمة والذكاء الإصطناعي. لدينا القدرة على تحويل الأفكار النظرية إلى
          منتجات واقعية تسهم في تحسين نوعية الحياة. نفتخر بتاريخنا الحافل من
          الإنجازات والتفرد في مشروعات محلية ودولية. فريقنا من المهندسين و الفنيين المختصين
          والذين يعملون بلا كلل لضمان تقديم حلول عالية الجودة تلبي احتياجات عملائنا.
          نحن هنا لتحقيق التغيير الإيجابي ونسعى دائمًا لمستقبل أفضل.
        </p>
      </div>

      <div className="container generalmanegaer">
        <div className="left">
          <div className="word">
            <h5>  كلمة المدير العام <h6 className="engabdlahh">المهندس عبدالله الكايد</h6></h5>
          </div>
          <p className="paraggraphgenralmaneger" dir="rtl">
            مرحبًا بكم في شركتنا، حيث يكمن الشغف في تحويل الأفكار إلى واقع ملموس
            لتحقيق الابتكار والتفوق في مجالات تقنية متعددة تخدم كافة قطاعات
            المجتمع.
            نحن نستند إلى عقود من الخبرة ونعمل
            بجد لتحويل رؤيتنا إلى حقيقة قائمة لتطوير
            المجتمعات وتحسين الحياة من خلال التكنولوجيا والابتكار.
            نشجعكم على اكتشاف مزيد
            مما نقدمه والانضمام إلينا في رحلتنا نحو إحداث تغيير إيجابي وتحسين
            مستقبلنا المشترك.
          </p>
            <div className="othersection">
              <p dir="rtl" className="othersectiopara">
                رؤيتنا في الشريحة المثالية هي أن نكون الرائدون في مجال التكنولوجيا
                والابتكار على مستوى العالم. نرغب في توسيع انتشارنا لنصل إلى أكبر عدد
                ممكن من الأفراد والمؤسسات، مُقدمين حلولاً تكنولوجية تُحدث فارقاً
                حقيقياً في حياتهم. نهدف لأن يُعتبر اسمنا مُرادفًا للجودة، الأمان
                والاستدامة من خلال التحلي بالنزاهة والتفرد. نسعى لتحقيق تأثير
                إيجابي يُشعر كل شخص بأن الغد سيكون أفضل.
              </p>
            </div>
        </div>
        <div className="right imageaboutus">
          <img
            src="https://ik.imagekit.io/nbnhyhh5w/idealchip%20website%20section/3.jpg?updatedAt=1696157124272"
            alt="general manegaer"
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
