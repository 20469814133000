import React from 'react';
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import App from "./App";
import QMS from "./components/qms/qms";
import About from "./components/about/About";
import Contact from "./components/contact/indexContact";
import Category from "./components/product/category";
import Cateproduct from "./components/product/categoryproduct";

function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/product" element={<Category />} />
        <Route path="/category/:id" element={<Cateproduct />} />
        <Route path="/product/:name" element={<QMS />} />
        <Route path="/contact_us" element={<Contact />} />
      </Routes>
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<MainApp />);
