import React from 'react'

export default function Services() {
    return (
        // <!--  Start Services Section -->
        <section className="services" id='services'>
            <div className="container">
                <div className="row">
                    <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center wow fadeInUp">
                        <div className="alt-font text-pink-color margin-5px-bottom text-uppercase text-small letter-spacing-1"></div>
                        <h5 className="margin-10px-bottom font-weight-300"><span style={{ color: '#003c8c' }} className="font-weight-500">featured</span> Products</h5>
                        <div className="separator-line-horrizontal-medium-light2 bg-pink center-col"></div>
                    </div>
                </div>

                <div className="row">
                    {/* <!-- start services item --> */}
                    <div data-wow-duration="1.1s" className="col-lg-3 col-6 col-md-4 col-xs-6 margin-30px-bottom xs-margin-20px-bottom wow fadeInUp ">
                        <div className="bg-white rounded border border-width-2 border-color-extra-light-gray text-center padding-30px-tb xs-padding-15px-lr feature-box2 ">
                            <div className="display-inline-block margin-20px-bottom xs-margin-10px-bottom js"><i style={{ color: "#003c8c" }} className=""></i><img src='https://ik.imagekit.io/nbnhyhh5w/line-up-blue.png?updatedAt=1695650442965' alt='img'/></div>
                            <div className="alt-font text-extra-dark-gray margin-10px-bottom text-capitalize">queue management system</div>
                            {/* <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-bottom margin-15px-top"></div> */}
                            {/* <p className="width-75 md-width-90 xs-width-100 center-col no-margin-bottom"> With a passion for originality, we leverage imaginative thinking to deliver fresh solutions that inspire and captivate.</p> */}
                        </div>
                    </div>
                    {/* <!-- end services item --> */}
                    {/* <!-- start services item --> */}
                    <div data-wow-duration="0.4s" className="col-lg-3 col-6 col-md-4 col-xs-6 margin-30px-bottom xs-margin-20px-bottom wow fadeInUp">
                        <div className="bg-white rounded border border-width-2 border-color-extra-light-gray text-center padding-30px-tb xs-padding-15px-lr feature-box2">
                            <div className="display-inline-block margin-20px-bottom xs-margin-10px-bottom"><i style={{ color: "#003c8c" }} className=""></i><img src='https://ik.imagekit.io/nbnhyhh5w/data%20logger.png?updatedAt=1695725185259' alt='img'/></div>
                            <div className="alt-font text-extra-dark-gray margin-10px-bottom text-capitalize">data logger system</div>
                            {/* <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-bottom margin-15px-top"></div> */}
                            {/* <p className="width-75 md-width-90 xs-width-100 center-col no-margin-bottom">Our cutting-edge technology and intelligent solutions bring innovation and efficiency to solve your challenges in today's fast-paced world.</p> */}
                        </div>
                    </div>
                    {/* <!-- end services item --> */}

                    {/* <!-- start services item --> */}
                    <div data-wow-duration="0.6s" className="col-lg-3 col-6 col-md-4 col-xs-6 margin-30px-bottom xs-margin-20px-bottom wow fadeInUp">
                        <div className="bg-white rounded border border-width-2 border-color-extra-light-gray text-center padding-30px-tb xs-padding-15px-lr feature-box2">
                            <div className="display-inline-block margin-20px-bottom xs-margin-10px-bottom"><i style={{ color: "#003c8c" }} className=""></i><img src='https://ik.imagekit.io/nbnhyhh5w/nurse%20(1).png?updatedAt=1695725185227' alt='img'/></div>
                            <div className="alt-font text-extra-dark-gray margin-10px-bottom text-capitalize">nurse call system</div>
                            {/* <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-bottom margin-15px-top"></div> */}
                            {/* <p className="width-75 md-width-90 xs-width-100 center-col no-margin-bottom">  Our focus is on nurturing meaningful connections, fostering trust, and creating long-lasting partnerships for shared prosperity.</p> */}
                        </div>
                    </div>
                    {/* <!-- end services item --> */}



                    {/* <!-- start services item --> */}
                    <div data-wow-duration="1.6s" className="col-lg-3 col-6 col-md-4 col-xs-6 margin-30px-bottom xs-margin-20px-bottom wow fadeInUp">
                        <div className="bg-white rounded border border-width-2 border-color-extra-light-gray text-center padding-30px-tb xs-padding-15px-lr feature-box2">
                            <div className="display-inline-block margin-20px-bottom xs-margin-10px-bottom"><i style={{ color: "#003c8c" }} className=""></i><img src='https://ik.imagekit.io/nbnhyhh5w/messurment.png?updatedAt=1695725185269' alt='img'/></div>
                            <div className="alt-font text-extra-dark-gray margin-10px-bottom text-capitalize text-nowrap test15">environment measurement system</div>
                            {/* <div className="separator-line-horrizontal-medium-light3 bg-pink center-col margin-15px-bottom margin-15px-top"></div> */}
                            {/* <p className="width-75 md-width-90 xs-width-100 center-col no-margin-bottom">With commitment, we deliver exceptional standards, ensuring excellence in every product and service we provide.</p> */}
                        </div>
                    </div>
                    {/* <!-- end services item --> */}
                </div>
            </div>
            {/* <Chart /> */}
        </section>
        // <!-- End Services Section -->

    )
}
