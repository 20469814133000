import React, { useState } from "react";

const Partners = () => {
  const [filterCategory, setFilterCategory] = useState("All");
  const handleFilterChange = (value) => {
    setFilterCategory(value);
  };

  const categories = [
    { name: "All", value: "All" },
    { name: "energy", value: "energy" },
    { name: "Education", value: "edu" },
    { name: "Telecom", value: "tel" },
    { name: "Goverment", value: "gov" },
    { name: "Private", value: "priv" },
  ];

  const image_kilt_url = "https://ik.imagekit.io/bw4cwthqo/";
  const image_config = "tr:q-40";
  const companies = [
    { name: "logos/zeraa.jpg", cat: "gov" },
    { name: "logos/zu.jpg", cat: "edu" },
    { name: "logos/zain.png", cat: "tel" },
    { name: "logos/zarqa.jpg", cat: "gov" },
    { name: "logos/yarmok.jpg", cat: "edu" },
    { name: "logos/vapco.png", cat: "priv" },
    { name: "logos/united.png", cat: "priv" },
    { name: "logos/umnieh.png", cat: "tel" },
    { name: "logos/tmrw.png", cat: "priv" },
    { name: "logos/sena3a.jpg", cat: "gov" },
    { name: "logos/tebeah.jpg", cat: "priv" },
    { name: "logos/total.png", cat: "energy" },
    { name: "logos/sawame3.jpg", cat: "gov" },
    { name: "logos/tamweelkom.jpeg", cat: "priv" },
    { name: "logos/orange.jpg", cat: "tel" },
    { name: "logos/our_water.jpg", cat: "gov" },
    { name: "logos/nabelsi.png", cat: "priv" },
    { name: "logos/muteh.jpg", cat: "edu" },
    { name: "logos/GULF.png", cat: "priv" },
    { name: "logos/mesfah.png", cat: "gov" },
    { name: "logos/ju.png", cat: "edu" },
    { name: "logos/ju_hosp.png", cat: "gov" },
    { name: "logos/just.jpg", cat: "edu" },
    { name: "logos/jaesh.jpg", cat: "gov" },
    { name: "logos/jopetrol.png", cat: "energy" },
    { name: "logos/ethmar.jpg", cat: "priv" },
    { name: "logos/ghadeer.jpg", cat: "priv" },
    { name: "logos/custom.jpg", cat: "gov" },
    { name: "logos/adl.jpg", cat: "priv" },
    { name: "logos/dewan.jpg", cat: "priv" },
    { name: "logos/coca.png", cat: "priv" },
    { name: "logos/amaneh.jpg", cat: "gov" },
    { name: "logos/armed_forces.jpg", cat: "gov" },
    { name: "logos/csbd.jpg", cat: "gov" },
    { name: "logos/aqaba.jpg", cat: "gov" },
    { name: "logos/air.jpg", cat: "gov" },
  ];
  const filteredCompanies =
    filterCategory === "All"
      ? companies
      : companies.filter((company) => company.cat === filterCategory);

  return (
    <div>
      <section className="partners" data-scroll-index="3">
        <div className="container">
          <div className="row">
            <div className="col-12 center-col margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom text-center wow fadeInUp">
              <div className="alt-font text-pink-color margin-5px-bottom text-uppercase letter-spacing-1">
                Success Partners
              </div>
              <h5 className="margin-10px-bottom font-weight-300">
                Our{" "}
                <span style={{ color: "#272567" }} className="font-weight-500">
                  Valuable{" "}
                </span>{" "}
                Clients
              </h5>
              <div className="separator-line-horrizontal-medium-light2 bg-pink center-col"></div>
            </div>
          </div>
          <div className="row">
            {/* <!-- Start links --> */}
            <div className="filtering col-sm-12 text-center">
              {categories
                ? categories.map((ele, index) => {
                    return (
                      <span
                        key={index + "_span"}
                        onClick={(e) => handleFilterChange(ele.value)}
                        className={filterCategory === ele.value ? "active" : ""}>
                        {ele.name}
                      </span>
                    );
                  })
                : ""}
            </div>
            {/* <!-- End links --> */}
            <div className="clearfix"></div>
          </div>

          <div className="row justify-content-center align-content-center text-center mt-4 m-7">
            {filteredCompanies.map((company, index) => (
              <div
                key={index + "_div"}
                className="col-2 mt-3 align-center logo_div"
                style={{
                  backgroundImage: `url(${
                    image_kilt_url + image_config + "/" + company.name
                  })`,
                  // width:"200px",
                  // height:"200px"
                }}>
                {/* <img id='logos' className='' src={image_kilt_url+image_config+"/"+company.name} alt={company.name} /> */}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
