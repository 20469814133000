import React, { useState, useRef } from 'react';
import Map from "../map/map";
import axios from 'axios';



export default function Contact() {

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('initialColor');

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const resetForm = () => {
    nameRef.current.value = "";
    emailRef.current.value = "";
    phoneRef.current.value = "";
    subjectRef.current.value = "";
    messageRef.current.value = "";
  };

  const toggleBackgroundColor = () => {
    setBackgroundColor('rgba(0, 0, 0, 0.3)');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData(e.target);    
    
    try {
      const response = await axios.post('https://www.idealchip.com/backEndAPI/api/create-test', formData);
      if (response.data.message === 'Contact created successfully') {
        setSuccessMessage('Contact created successfully');
        setErrorMessage('');
        resetForm();
      }
    } catch (error) {
      setErrorMessage('An error occurred while submitting the form');
      setSuccessMessage('');
    }
  };

  return (
    <section
      id="contact_us"
      className="contact bg-light-gray no-padding"
      data-scroll-index="5"
      >
      <div className="container-fluid">
        <div className="row">
          {/* <!-- start quickly contact --> */}
          <div className="col-lg-6 map">
            <Map />
            <div className="padding-ten-all sm-padding-50px-tb sm-no-padding-lr info" style={{ background: backgroundColor }}>
              <div className="wow fadeInUp">
                <div className="alt-font margin-5px-bottom text-uppercase  text-center letter-spacing-1">
                </div>
                <h5 className="font-weight-300 margin-10px-bottom text-center text-white">
                  Quickly <span className="font-weight-500">Contact</span> Us
                </h5>
                <div className="margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom separator-line-horrizontal-medium-light2 bg-pink center-col"></div>
              </div>
              <span className="icon-toggle" onClick={toggleBackgroundColor}>
                <i
                  id="marker"
                  className="fa-solid fa-location-dot"
                  style={{ color: "#003c8c" }}></i>
              </span>
              <div className="item wow fadeInUp" data-wow-delay="0.2s">
                <span className="icon">
                  <i className="fa-solid fa-mobile-screen-button"></i>
                </span>
                <div className="cont">
                  <a href="tel:+96264886405">
                    <h6 className="text-white">Phone: </h6>
                    <p  className="text-white">+962 7 9021 7000</p>
                  </a>
                </div>
              </div>
              <div className="item wow fadeInUp" data-wow-delay="0.4s">
                <span className="icon">
                  <i className="fa-solid fa-map-location-dot"></i>
                </span>
                <div className="cont">
                  <a target='_blank' rel="noreferrer" href="https://maps.app.goo.gl/7BtJpeKDB8wFMJ537">
                    <h6 className="text-white">Address: </h6>
                    <p  className="text-white">
                      {" "}
                      Complex No 213, Al Shaheed St,tabarbour ,Amman
                    </p>
                    <p  className="text-white">P.O.Box 212191 Amman, 11121.</p>
                  </a>
                </div>
              </div>
              <div className="item wow fadeInUp" data-wow-delay="0.6s">
                <span className="icon">
                  <i className="fa-solid fa-envelope-open-text"></i>
                </span>
                <div className="cont">
                  <a href="mailto:info@idealchip.com">
                    <h6 className="text-white">Email: </h6>
                    <p  className="text-white">idealchip@idealchip.com</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end quickly contact --> */}
          {/* <!-- start get in touch --> */}
          <div className="col-lg-6">
            <div className="padding-ten-all sm-padding-50px-tb sm-no-padding-lr">
              <div className="wow fadeInUp">
                <div className="alt-font text-pink-color margin-5px-bottom text-uppercase  text-center letter-spacing-1">
                </div>
                <h5 className="font-weight-300 margin-10px-bottom text-center">
                  Get{" "}
                  <span
                    style={{ color: "#272567" }}
                    className="font-weight-500">
                    In Touch
                  </span>{" "}
                </h5>
                <div className="margin-70px-bottom sm-margin-40px-bottom xs-margin-30px-bottom separator-line-horrizontal-medium-light2 bg-pink center-col"></div>
              </div>
              <form
                method="post"
                onSubmit={handleSubmit}
                className="mailform off2">
                <input type="hidden" name="form-type" value="contact" />
                <div className="row">
                  <div className="col-md-6">
                    <input
                      ref={nameRef}
                      type="text"
                      name="name"
                      placeholder="Your Name:"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      ref={emailRef}
                      type="text"
                      name="email"
                      placeholder="Email:"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      ref={phoneRef}
                      type="text"
                      name="phone"
                      placeholder="Telephone:"
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      ref={subjectRef}
                      type="text"
                      name="subject"
                      placeholder="Subject:"
                    />
                  </div>
                  <div className="col-md-12">
                    <textarea
                      ref={messageRef}
                      name="message"
                      rows="5"
                      placeholder="Message:"></textarea>
                  </div>
                  <div className="col-md-12 text-center">
                    <button type="submit" className="btn">
                      Send Message
                    </button>
                    {successMessage && <div className="alert alert-success mt-4">{successMessage}</div>}
                    {errorMessage && <div className="alert alert-danger mt-4">{errorMessage}</div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- end get in touch --> */}
        </div>
      </div>
    </section>
  );
}
