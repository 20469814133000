
import Navbar from "./components/navbar/navbar";
import Header from "./components/header/header";
import Services from "./components/services/Services";
import Counter from "./components/counter/Counter";
// import Portifolio from "./components/portifolio/portifolio";
// import Testemonials from "./components/testemonials/testemonials";
import Footer from "./components/footer/footer";
import Contact from "./components/contact/Contact";
import Partners from "./components/partners/partners";
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { useEffect } from "react";
// import Products from "./components/products/Products";

function App() {
  useEffect(() => {

  }, [])
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Services />
      <Counter />
      {/* <Portifolio /> */}
      {/* <Products /> */}
      {/* <ProductSlider /> */}
      {/* <Projects /> */}
      {/* <Testemonials /> */}
      <Partners />
      {/*<Team />*/}
      {/*<Blog />*/}
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
