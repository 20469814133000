import React from "react";
export default function Header() {
  const handleScrollToAbout = (e) => {
    e.preventDefault(); // Prevent default behavior of jumping to anchor
    const aboutSection = document.getElementById("services");
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  return (
    <header
      className="header video-container background-position-top valign bg-img no-transition"
      id="header"
      data-overlay-dark="2">
      <video
        autoPlay
        muted
        loop
        id="myVideo"
        onCanPlayThrough={(e) => {
          e.target.muted = true;
          e.target.play();
        }}>
        <source
          src="https://ik.imagekit.io/bw4cwthqo/bg_video/header_bg_video.mp4?updatedAt=1686821910346"
          type="video/mp4"
        />
      </video>
      <div className="container">
        <div className="row">
          {/* <animated.div style={{ ...springs }} > */}

          <div className="col-12 text-center caption">
            <h3 className="alt-font font-size28 sm-font-size18 no-margin fade-in">
              <b>iDEAL</b>
              <span style={{ fontWeight: "350" }}>CHiP</span> Electronics{" "}
            </h3>
            <h1 className="banner-headline clip">
              <span className="blc mx-2">
                {" "}
                We build the <b className="is-visible">Future</b> <br />{" "}
                <span style={{ fontSize: "1.2rem" }}> of </span>
                <svg>
                  <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                    Smart City
                  </text>
                </svg>
              </span>
            </h1>

            <p
              className="margin-30px-bottom margin-30px-left margin-30px-right sm-margin-20px-bottom  xs-display-none"
              style={{ fontSize: "1.2rem" }}>
              We are specialized in delivering innovative solutions tailored to
              the needs of smart cities, aiming to provide the most effective
              and efficient strategies for enhancing urban living.
            </p>
            {/* <a href="" style={{opacity:'0.8'}} className="btn margin-30px-top" data-scroll-nav="4">
                            <span>Explore!  </span>
                        </a> */}
          </div>
        </div>
      </div>

      <div className="arrow">
        <a href="#services" onClick={handleScrollToAbout}>
          <i
            style={{ fontSize: "22px" }}
            className="custom-icon fa-solid fa-arrow-down fa-beat"
          ></i>
        </a>
      </div>
    </header>
  );
}
