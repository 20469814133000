import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import SideProbar from "../sidebar/sidebar";

export default function QMS({ data }) {

const { name } = useParams();
const [product, setProduct] = useState(null);
const [imageLinks, setImageLinks] = useState([]);

const [slidePosition, setSlidePosition] = useState(0);
const sliderRef = useRef(null);


const navigate = useNavigate();

const goBack = () => {
  navigate(-1);
};

useEffect(() => {
  axios.get(`https://www.idealchip.com/backEndAPI/api/product/${name}`)
  .then(res => {
  setProduct(res.data);
  setImageLinks(JSON.parse(res.data.image_links));
  })
  .catch(error => {
  console.error('There was an error fetching the data', error);
  });
}, [name]);


useEffect(() => {
  const interval = setInterval(() => {
      setSlidePosition(prevPosition => {
          const maxSlidePosition = -640 * (imageLinks.length - 4);
          if (prevPosition <= maxSlidePosition) {
              return 0;
          } else {
              return prevPosition - 640 * 3;
          }
      });
  }, 3000);

  return () => clearInterval(interval);
}, [imageLinks]);

return (
<section className="no-padding">
  <Navbar SingleProject={true} />
  {product ? (
  <section className='no-padding'>
    <section id="sec_bg">
      <section className="blog-header">
        <div className="container">
          <div className="row">
            <div className=" col-md-12">
              <h5>{product.fullName}</h5>
              <a href="/">Home</a>
              <a href="/product">category</a>
              <button onClick={goBack} className="button-hover">product</button>
            </div>
          </div>
        </div>
      </section>
    </section>
    <div className="qms_content mt-5 container-fluid">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1">
          </div>
          <div className="col-md-10 p-5">
            <div className="row">
              <div className="col-md-9 text-center headerimage">
                <img id="" src={product.image} alt="qms_product" />
              </div>
              <div 
                className='container paragraph' 
                dangerouslySetInnerHTML={{ __html: product.paragraph }}
              ></div>
              <div 
                className='slider' 
                ref={sliderRef} 
                >
                  {imageLinks.map((link, index) => (
                  <img 
                    style={{ transform: `translateX(${slidePosition}px)` }}
                    key={index}
                    src={link} 
                    alt={`slider-${index}`} 
                    className={`slide ${index < 4 ? 'active' : ''}`}
                    />
                  ))}
              </div>
            </div>
            {/* contact us section*/}
            <div className="row mt-5">
              <div className="col-md-10 mt-5 container-fluid">
                <h6 className="blue">
                  <b>How I can Get This ??</b>
                </h6>
                <p>You can get the ({product.fullName}) by contacting us via email or phone number </p>
                <a className="contact_us_qms" href="/contact_us">
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  ) : (
  <div>
    <h6 className="container mt-5">{"loading. . ."}</h6>
  </div>
  )}
  <Footer />
</section>
);
}
