import React, { useState, useEffect } from "react";

export default function Footer() {
  const [isVisible, setIsVisible] = useState(false);
  const currentYear = new Date().getFullYear();

  // Scroll to top smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  
  return (
    <footer className="bg-white">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            {/* <!-- start footer logo --> */}
            <div className="col-md-12 col-sm-12 text-center wow fadeInUp">
              <div className="text-center">
                <a href="/">
                  <img
                    src="https://ik.imagekit.io/bw4cwthqo/logos/logo_elec_big_edit.png?updatedAt=1686824811898"
                    alt="Ideal chip logo"
                    title="Ideal Chip For Electronics  Logo"
                    width="300rem"
                  />
                </a>
              </div>
              <span className="font-size11 alt-font text-uppercase  display-block letter-spacing-1 text-dark-gray margin-15px-top margin-3px-bottom xs-margin-fifteen"></span>
            </div>
            {/* <!-- end footer logo --> */}
            {/* <!-- start social media link --> */}
            <div className="col-md-12 col-sm-12 text-center wow fadeInUp">
              <div className="footer-social">
                <a href="https://web.facebook.com/IdealChip1997">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.youtube.com/@idealchipelectronics7311">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.linkedin.com/company/idealchip-electronics/">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            {/* <!-- end social media link --> */}
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-black">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 text-center">
              <span id="copyright" className="font-size12 text-uppercase letter-spacing-1">
                © 1997-{currentYear}, idealchip.com Inc , All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
      {isVisible && (
        <div
          id="back-to-top"
          className="back-to-top reveal"
          onClick={scrollToTop}
        >
          <i className="fa fa-angle-up"></i>
        </div>
      )}
    </footer>
  );
}

