import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

export default function Category() {
  const [category, setCategory] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://www.idealchip.com/backEndAPI/api/category/${id}`)
      .then((res) => {
        setCategory(res.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data", error);
      });
  }, []);

  return (
    <section className="no-padding">
      <Navbar SingleProject={true} />
      <section id="sec_bg">
        <section className="blog-header">
          <div className="container">
            <div className="row">
              <div className="col-md-12 prodcamp">
                <h5>{"Products"}</h5>
                <a href="/">Home</a>
                <a href="/product">products</a>
                {category && (
                  <a href={`/category/${category.id}`}>{category.cat_name}</a>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="blog">
        <div className="container">
                {category&&(
                    <div className="row rowproductcategory">
                      {category.product.map((product) => (
                        <div 
                          key={product.id}
                          className="col-lg-4 sm-margin-30px-bottom wow fadeInUp"
                          data-wow-delay="0.2 s"
                          style={{
                            visibility: "visible",
                            animationName: "fadeInUp",
                          }}
                          >
                          <a href={`/product/${product.name}`}>
                            <div
                              className='item text-center'
                              style={{ height: '270px' }}
                            >
                              <div className="content" style={{ height: "270px" }}>
                                <div
                                  className="tag alt-font text-center d-flex m-auto productimg"
                                  style={{
                                    height: "10rem",
                                    color: "white",
                                    opacity: 0.88,
                                    width: "100%",
                                    backgroundImage: `url(${product.image})`,
                                  }}
                                >
                                  <h6
                                    className="m-auto p-2 font-weight-bold text-capitalize"
                                    style={{ color: "whitesmoke" }}
                                  >
                                    {}
                                  </h6>
                                </div>
                                <div className="separator-line-horrizontal-full bg-medium-gray margin-20px-tb"></div>
                                <a
                                  href={`#sa`}
                                  className="read-more text-center"
                                >
                                {product.fullName}
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
        </div>
      </section>
      <Footer />
    </section>
  );
}