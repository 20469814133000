import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import Contact from "./Contact";
export default function indexContact() {
  return (
    <section
      id="contact_us"
      className="contact no-padding"
      data-scroll-index="5">
      <Navbar />

      <div className="ContactSectionSingelPage">
        <Contact />
      </div>

      <Footer />
    </section>
  );
}
